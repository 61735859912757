































import { Component, mixins, Prop } from 'nuxt-property-decorator';
import MediaMixin from '~/mixins/MediaMixin';

@Component
export default class ImageGraphics extends mixins(MediaMixin) {
  @Prop() data!: { width: string, height: string };
  $refs !: { container: HTMLUnknownElement };
  isSvg: boolean = this.media.filename.endsWith('.svg');

  mounted () {
    const width = process.browser && this.$refs.container ? this.$refs.container.clientWidth : 250;
    this.imageurlSharp = this.generateImageUrl({ width, dpi: this.dpi, format: 'png' });
  }
}
